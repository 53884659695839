// Generated by Framer (6597ba8)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/CgpwDOq5m";

const cycleOrder = ["Ygn4Rr9ak"];

const variantClassNames = {Ygn4Rr9ak: "framer-v-dvtz7r"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "Ygn4Rr9ak", title: GeSfCETih = "Még több", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "Ygn4Rr9ak", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-w9KxD", sharedStyle.className, classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<motion.div {...restProps} className={cx("framer-dvtz7r", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Ygn4Rr9ak"} ref={ref} style={{...style}} transition={transition}><RichText __fromCanvasComponent children={<React.Fragment><motion.h3 className={"framer-styles-preset-1spua54"} data-styles-preset={"CgpwDOq5m"}>Még több</motion.h3></React.Fragment>} className={"framer-rrhcha"} layoutDependency={layoutDependency} layoutId={"CmJn8Eg7h"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={GeSfCETih} transformTemplate={(_, t) => `translate(-50%, -50%) ${t}`} transition={transition} verticalAlignment={"top"} withExternalLayout/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-w9KxD [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-w9KxD * { box-sizing: border-box; }", ".framer-w9KxD .framer-1qqhagn { display: block; }", ".framer-w9KxD .framer-dvtz7r { height: 25px; overflow: hidden; position: relative; width: 79px; }", ".framer-w9KxD .framer-rrhcha { flex: none; height: auto; left: 49%; position: absolute; top: 50%; white-space: pre; width: auto; }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 25
 * @framerIntrinsicWidth 79
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"GeSfCETih":"title"}
 */
const FramersLzqtPfYS: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramersLzqtPfYS;

FramersLzqtPfYS.displayName = "Gomb";

FramersLzqtPfYS.defaultProps = {height: 25, width: 79};

addPropertyControls(FramersLzqtPfYS, {GeSfCETih: {defaultValue: "Még több", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramersLzqtPfYS, [...sharedStyle.fonts])